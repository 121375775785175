import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom } from 'rxjs';
import { fadeInSlideUpAnimation } from 'src/app/animations/animations';
import { StatsService } from 'src/app/service/api/stats.service';

@Component({
  selector: 'app-cluster-dashboard',
  templateUrl: './cluster-dashboard.component.html',
  styleUrl: './cluster-dashboard.component.scss',
  animations: [fadeInSlideUpAnimation()]
})
export class ClusterDashboardComponent implements AfterContentInit {
  clusterId?: string | null;
  stats?: any | null;
  loading = false;

  constructor(
    private statsService: StatsService,
    private logger: NGXLogger,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.clusterId = this.route.snapshot.paramMap.get('clusterId');
  }

  ngAfterContentInit(): void {
    this.loadClusterStats();
  }

  editClusterButtonClicked() {
    this.router.navigate(['clusters', this.clusterId, 'edit']);
  }

  async loadClusterStats() {
    this.loading = true;
    try {
      const statsResponse = await lastValueFrom(this.statsService.getClusterStats(this.clusterId!));
      this.stats = statsResponse.data;
    } catch(e) {
      if (e instanceof HttpErrorResponse) {

      }
      this.logger.error(`[${this.constructor.name}] error retrieving cluster\'s stats: ` + JSON.stringify(e));
    }

    this.loading = false;
  }

}
