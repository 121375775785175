<div *ngIf="loading">
    <div class="align-items-center">
        <p-progressSpinner styleClass="w-4rem h-4rem alpha-spinner block" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    </div>
</div>

<div *ngIf="clusterId != null && stats">
    <div [@fadeInSlideUp] class="grid">
        <div class="col-12 md:col-4 animated-element">
            <p-card header="{{'LABELS.users' | translate | titlecase}}" class="flex dashboard-tile">
                <div class="flex flex-row justify-content-between align-items-center">
                    <div class="flex label justify-content-center">
                        <span class="flex text-7xl">{{stats.users}}</span>
                    </div>
                    
                    <div class="icon">
                        <i class="pi pi-users text-4xl"></i>
                    </div>
                    
                </div>
            </p-card>
        </div>
        <div class="col-12 md:col-4 animated-element">
            <p-card header="{{'LABELS.organizations' | translate | titlecase}}" class="flex dashboard-tile">
                <div class="flex flex-row justify-content-between align-items-center">
                    <div class="flex label justify-content-center">
                        <span class="flex text-7xl">{{stats.organizations}}</span>
                    </div>
                    
                    <div class="icon">
                        <i class="pi pi-shield text-4xl"></i>
                    </div>
                    
                </div>
            </p-card>
        </div>
    </div>
    <p-button [label]="'LABELS.edit-cluster' | translate" (onClick)="editClusterButtonClicked()" [outlined]=true></p-button>
</div>
